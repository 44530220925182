.title
  margin: 0
  padding: 0
  color: $dark
  font-size: 2em
  font-weight: 600
  +v-xs
    font-size: 1.2em
  &-mini
    font-size: 0.85em
  &-price
    padding-left: 64px
    font-size: 6em
    font-weight: 700
    line-height: 1.75
    +v-xs
      font-size: 3em
      padding-left: 32px
    span
      font-size: .4em
  &-cta
    font-size: 2.25em
    font-weight: 400
    line-height: 1.6
    +v-sm
      font-size: 1.65em
      font-weight: 400
      line-height: 1.4
    +v-xs
      font-size: 1.15em
      line-height: 1.25
  &-intro
    color: $dark
    font-size: 6em
    font-weight: 700
    font-family: $intro
    +v-sm
      zoom: .75
    +v-sm
      zoom: .35
    img
      transform: translateY(-24px)
      &:first-of-type
        transform: translateY(-8px)
  &-sub
    font-size: 1.05em
    font-weight: 600
    +v-xs
      font-size: .9em
      margin-left: 3%
      margin-right: 3%
    &.w-60
      +v-xs
        margin: 0 auto
        min-width: 96% !important
    &.w-40
      +v-sm
        margin: 0 0 60px
        min-width: 100% !important

footer
  +v-sm
    margin-top: 80px
  a
    &:hover
      color: $dark !important
  .f
    &-text
      color: $footer
      font-size: 1em
      font-weight: 500
      line-height: 1.5

img
  +v-md
    max-width: 96% !important

.m-hide
  +v-md
    display: none !important

.m-tc
  +v-md
    text-align: center !important
