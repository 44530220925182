@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:wght@400;500;600&family=Oswald:wght@500&display=auto')

$body: #FAFAFA
$theme: #D3B1DF
$dark: #333
$white: #fff
$light: #DDDDDD
$medium: #444
$lite: #6e6e6e

$button: $theme

$blue: #40BAFF
$violet: #8E4A95
$darkblue: #3f436c
$pink: #EA4E98
$marine: #00AACB
$footer: #44727B
$grey: #cdcdcd
$green: #99b660

$basic: 'Inter Tight', sans-serif
$intro: 'Oswald', sans-serif

=placeholder
  &::-webkit-input-placeholder
    @content
  &:-moz-placeholder
    @content
  &::-moz-placeholder
    @content
  &:-ms-input-placeholder
    @content
