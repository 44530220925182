.button
  display: inline-flex
  justify-content: center
  align-items: center
  height: 50px
  padding: 0 56px
  color: $dark !important
  font-size: 1em
  font-weight: 500
  border-radius: 40px
  transition: all ease-out .25s
  background-color: $white !important
  border: 2px solid $light !important
  outline: none !important
  &.pricing
    width: calc(100% - 20px)
    margin: 0 10px
    font-size: 1.15em !important
    font-weight: 400 !important
    letter-spacing: 3% !important
    padding: 16px 24px
    border-radius: 16px
    background: linear-gradient(180deg, rgba(144, 90, 150, 0.9) 41.98%, rgba(224, 85, 115, 0.9) 100%)
    &:hover
      opacity: .75
  &:hover
    color: $white !important
    background-color: $theme !important
    border: 2px solid $white !important
  &.dark
    color: $white !important
    background-color: $dark !important
    border: 2px solid $dark !important
  &.top
    height: 50px
    padding: 0 40px
    border: 2px solid $light !important
    &:hover
      border: 2px solid $theme !important
    +v-sm
      right: 80px
      top: 19px
      position: fixed
      height: 40px
      padding: 0 30px
    +v-xs
      top: 20px
      right: 72px
      height: 32px
      padding: 0 20px
      font-size: .85em
  &.cat
    height: 50px !important
    padding: 0 24px !important
    +v-sm
      height: 40px !important
      padding: 0 20px !important
    +v-xs
      display: inline-flex
      width: auto
      height: 30px !important
      padding: 0 20px !important
      font-size: 11px
      margin: 4px !important
    &:hover
      color: $dark !important
      background-color: $white !important
      border: 2px solid $light !important

.card-footer
  .button
    &.price
      border: none !important

  +v-sm
    display: flex
    width: 60%
    margin: 24px auto
  +v-xs
    display: flex
    width: 100%
    margin: 24px 0
    padding: 0
    font-size: 0.82em

#price
  .button
    border: none !important
    &:hover
      color: $white !important
      background-color: $theme !important
      border: 2px solid $white !important


#top
  .button
    +v-sm
      display: inline-flex
      width: 40%
      margin: 0 24px 0 0
    +v-xs
      display: flex
      width: 100%
      margin: 24px 0
